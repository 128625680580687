import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { sendWelcomeEmail } from '../../services/welcomEmail';
// import { findUser } from '../../services/findUser';
import {
  Title,
  Subtitle,
  Button,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  BackButtondiv,
  BackButtonnext,
  ContentContainer,
  LoaderContainer,
  Spinner,
} from './step4.styes';
import { useAuth } from '../../context/AuthContext';

const Step4 = () => {
  const history = useHistory();
  const { loginProvider } = useAuth();
  const { data } = loginProvider || {};
  const [loading, setLoading] = useState(false);

  // const fetchData = async () => {
  //   setLoading(true); // Set loading state to true
  //   const user = localStorage.getItem('user');
  //   if (user) {
  //     const parsedUser = JSON.parse(user);
  //     const { id, email } = parsedUser;
  //     if (id && email) {
  //       // const emailSent = await sendWelcomeEmail(id, email);
  //       // if (emailSent) {
  //       //   // console.log('Welcome email sent successfully:', emailSent);
  //       // } else {
  //       //   // console.log('Failed to send welcome email.');
  //       // }
  //       return;
  //     }
  //   }
  //   if (data) {
  //     const email2 = data.email;

  //     if (email2) {
  //       const response = await findUser(email2);

  //       // Check if the response is successful
  //       if (response && response.data && Array.isArray(response.data)) {
  //         const userData = response.data[0]; // Assuming the response is an array
  //         const { id, email } = userData;

  //         if (id && email) {
  //           // const emailSent = await sendWelcomeEmail(id, email);
  //           // if (emailSent) {
  //           //   //  console.log(' sended welcome email.');
  //           // } else {
  //           //   // console.log('Failed to send welcome email.');
  //           // }
  //         } else {
  //           // console.log('User data is incomplete in response.');
  //         }
  //       } else {
  //         // console.log('Failed to fetch user data.');
  //       }
  //     } else {
  //       // console.log('Email is not available in login provider data.');
  //     }
  //   } else {
  //     // console.log('No login provider data available.');
  //   }

  //   setLoading(false); // Ensure loading state is reset
  // };

  const onSubmit = () => {
    // fetchData();
    history.push('/user-panel/dashboard');
  };

  return (
    <ContentContainer>
      <Title>Welcome aboard!</Title>
      <Subtitle>
        You are now ready to explore Dinabite and take your social media game to
        the next level. Let&apos;s start this exciting journey together.
      </Subtitle>

      <DesktopBackButtonContainer>
        <NextButtonContainer>
          <Button onClick={onSubmit} disabled={loading}>
            {loading ? (
              <LoaderContainer>
                <Spinner />
              </LoaderContainer>
            ) : (
              'Go to Dashboard'
            )}
          </Button>
        </NextButtonContainer>
        <BackCancelContainer>
          {/* <DesktopBackButton onClick={onCancel}>Cancel</DesktopBackButton> */}
        </BackCancelContainer>
      </DesktopBackButtonContainer>
      <BackButtondiv>
        <BackButtonnext onClick={onSubmit}>Go to Dashboard</BackButtonnext>
      </BackButtondiv>
    </ContentContainer>
  );
};

export default Step4;
