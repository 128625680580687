import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  height:100%;
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  width: 100%;
  padding: 2rem; 
  border-radius: 10px;

  @media (max-width: 1024px) { /* Targeting laptops */
    padding: 1.5rem;
  }
    @media (max-width: 992px) { /* Targeting tablets and smaller devices */
      justify-content: flex-start;
      align-items: flex-start;  
        }
  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1024px) { /* Targeting laptops */
    flex-direction: column;
  }

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 55%;
  padding: 5rem;
  text-align: justify;

  @media (max-width: 1024px) { /* Targeting laptops */
    width: 100%;
    padding: 2rem 1rem; /* Adjusting padding for laptops */
  }

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    width: 100%;
    padding-right: 0;
  }
`;

export const SubHeading = styled.h2`
  font-size: 1.6rem;
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  text-align: justify;
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  margin-bottom: 1rem;

  @media (max-width: 1024px) { /* Targeting laptops */
    font-size: 1.5rem;
  }

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    font-size: 1.4rem;
  }
`;

export const Welcome = styled.p`
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  text-align: justify;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.black};
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    font-size: 1rem;
  }
`;

export const Description = styled.p`
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  text-align: justify;
  font-size: 1rem;
  color: ${props => props.theme.colors.black};
  line-height: 1.5;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    font-size: 0.9rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Button = styled.button`
  font-family: 'Inter', sans-serif;
  color: ${props => props.theme.colors.white};
  background-color:${props => props.theme.colors.red} ;
  padding: 0.8rem 1.7rem;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;

  &:hover {
    background-color: #d81b60;
  }

  @media (max-width: 768px) { /* Targeting tablets and smaller devices */
    width: 100%;
    font-size: 0.9rem;
  }
`;

export const ImageContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  max-width: 500px;

  img {
    width: 100%;
    max-width: 600px; /* Controls the image size */
    height: auto;

    @media (max-width: 992px) { /* Targeting tablets and smaller devices */
     display:none;
    }
  }
`;