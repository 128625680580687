import axios from '../lib/axios';

interface User {
  email: string;
}

interface ApiResponse {
  data: User[];
}
export const findUser = async (email: string) => {
  try {
    const response = await axios.post('/users/find', { email });
    return response;
  } catch (error) {
    return error;
  }
};
export const findUser2 = async (email: string): Promise<ApiResponse> => {
  try {
    const response = await axios.post('/users/find', { email });
    return response;
  } catch (error) {
    return { data: [] };
  }
};
export const checkCompanyExists = async (email: string): Promise<boolean> => {
  try {
    const response = await axios.post('/companies/check-company', { email });
    return response.data;
  } catch (error) {
    return false; // Return false if there's an error
  }
};
