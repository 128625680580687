import { Button, FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const ButtonAdd = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.red};
  padding: 10px 20px;
  border-radius: 100px;

  span:first-child {
    color: ${props => props.theme.colors.white};
    padding-right: 10px;
    font-weight: bolder;
  }
`;

export const HeaderContent = styled(FlexboxGrid)`
  margin-bottom: 20px;
`;

export const InfoContent = styled.div`
  background: white;
  padding: 20px;
  max-width: 450px;
  margin: 0 auto;

  p {
    text-align: center;

    a {
      color: blue;
      text-decoration: underline;
    }
  }
`;

export const PlatformIcon = styled.img`
  height: 35px;
  margin-left: 10px;
`;

export const NewPromotionContainer = styled.div`
  border: 1px solid red;
  margin-right: 30px;
  border-radius: 15px;
  border: 2px dashed ${props => props.theme.colors.grey};
  padding: 0 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 10px;
  }

  h5 {
    margin-top: 20px;
    color: ${props => props.theme.colors.darkGrey};
  }
`;

export const PlatformFilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 25px;
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 5px;
    font-size: ${props => props.theme.fonts.small};
  }
`;
