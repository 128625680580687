import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const sendverifyingEmail = async (email: string) => {
  try {
    const response = await axios.post('/users/resend-activation', { email });

    notification({
      type: 'success',
      title: 'Success',
      description:
        'A verification email link has been sent. Please check your email.',
    });
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.response && err.response.status === 429) {
      notificationError({
        title: 'Error',
        description: 'Too Many Requests. Try again after 1 minute.',
      });
    } else {
      notificationError({
        title: 'Error',
        description: 'Failed to send the verification email.',
      });
    }
    return err;
  }
};
