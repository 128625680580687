/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTheme } from 'styled-components';
import axios from '../../../lib/axios';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { disableTool } from '../../../services/disableTool';
import { handleTwitterToolChange } from '../../../services/twitterTools';
import TwitterWindow from '../../socialWindows/TwitterWindow';
import {
  ButtonAdd,
  ButtonContainer,
  Container,
  ParagraphColor,
  TextContainer,
} from '../Toogle.styles';
import { notificationError } from '../../../lib/notification';

interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
  newFormat?: boolean;
  clickedSocial?: string;
  socialNetworkPages?: {
    facebook: boolean;
    google: boolean;
    instagram: boolean;
    twitter: boolean;
    tiktok: boolean;
  };

}

interface TwitterToogleRef {
  handleClick: () => void;
}

const TwitterToogle = forwardRef<TwitterToogleRef, Props>(
  (
    {
      companyId,
      stateTool,
      dispatchTool,
      invalidateQuery,
      signup = false,
      newFormat = false,
      clickedSocial,
      socialNetworkPages,
    }: Props,
    ref,
  ) => {
    const theme = useTheme();
    const [hasFocus, setHasFocus] = useState(true);

  const [isTwitter, setIsTwitter] = useState<boolean>(false);
  const [url, setUrl] = useState<any>('');
  const activated =
    stateTool[TYPES_OF_SOCIAL_NETWORKS.TWITTER]?.enabled === true;
    
  const twitterChecked = async () => {
    try {
      const { success, auth_url } = await startOAuth2Flow();
      if (success) {
        setUrl(auth_url);
        setIsTwitter(true);
        handleTwitterToolChange({
          setIsTwitter,
          dispatchTool,
          companyId,
          invalidateQuery,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      //  console.error(`OAuth 2.0 flow failed: ${error.message}`);
    }
  };
    useImperativeHandle(
      ref,
      () => ({
        handleClick: () => {
          if (clickedSocial === 'TWITTER') {
            twitterChecked();
          }
        },
      }),
      [clickedSocial, twitterChecked],
    );
  const startOAuth2Flow = async () => {
    // Frontend sends a request to Backend to initiate OAuth 2.0
    const response = await axios.get('/tools/startTwitterAuth');

    return response.data;
  };

  useEffect(() => {
    const handleFocus = () => setHasFocus(!hasFocus); // Toggle focus state
    window.addEventListener('focus', handleFocus);
    return () => window.removeEventListener('focus', handleFocus); // Cleanup on unmount
  }, [hasFocus]);

    const rawError = localStorage.getItem('twitterError');

  useEffect(() => {
    const storedError = rawError ? JSON.parse(rawError) : null;
    if (storedError && storedError.access_denied) {
      notificationError({
        title: 'Error',
        description: 'The twitter could not be added.',
      });
      localStorage.removeItem('twitterError'); // Optional: Clear the error after showing the notification.
    }
  }, [rawError, hasFocus]);

  return (
    <>
      {isTwitter && <TwitterWindow auth_url={url} />}

        {activated && (
          <Container
            style={{
              justifyContent: signup ? 'end' : 'space-between',
            }}>
            {!signup && !newFormat && (
              <TextContainer>
                <ParagraphColor status={1}>Connected</ParagraphColor>
              </TextContainer>
            )}
            <ButtonAdd
              pad={!activated ? '8px 12px' : '8px 4px'}
              bgColor={signup ? 'black' : theme.colors.red}
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                disableTool(
                  stateTool[TYPES_OF_SOCIAL_NETWORKS.TWITTER].id,
                ).then(() => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TWITTER });
                  invalidateQuery?.();
                },
              );
              // .catch(error => {
              //   console.log('Error:', error);
              // });
            }}
            style={{
              width: signup ? '120px' : 'auto',
            }}>
            Disconnect
          </ButtonAdd>
          {/* <ButtonAdd bgColor={theme.colors.green} disabled>
            Connected
          </ButtonAdd> */}
          </Container>
        )}

        {!activated && (
          <ButtonContainer signup={signup}>
            <ButtonAdd
              bgColor={theme.colors.grey}
              pad={!activated ? '8px 12px' : '8px 4px'}
              onClick={(event: React.SyntheticEvent) => {
                event.stopPropagation();
                twitterChecked();
              }}
              signup={signup}>
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}

      {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? twitterChecked()
            : disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.TWITTER].id).then(
                () => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_TWITTER });
                  invalidateQuery?.();
                },
              )
        }
      /> */}
    </>
  );
});

export default TwitterToogle;
