/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import { Button, Modal } from 'rsuite';
import { ModalPosition, ModalSubtitle, ModalTitle,Button2,Button3 } from './ModalTools.styles';

interface Props {
  children: ReactNode;
  setDataModal: any;
  dataModal: any;
  description?: string;
  title?: string;
  step?: string;
  setStep?: any;
  setDataModalArray?: any;
}

const ModalTools: React.FunctionComponent<Props> = ({
  children,
  setDataModal,
  dataModal,
  description,
  title,
  step,
  setStep,
  setDataModalArray,
}: Props) => {
  const hideModal = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    setDataModal(null);
    if (setDataModalArray) setDataModalArray([]);

    if (step === 'page') setStep('page');
  };
  return (
    <ModalPosition show={!!dataModal} onHide={hideModal}>
      <Modal.Header>
        <ModalTitle>{title || 'Connect profiles'}</ModalTitle>
        <ModalSubtitle>{description || 'Select one'} </ModalSubtitle>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button2
          onClick={(event?: React.SyntheticEvent) => {
            event?.stopPropagation();
            hideModal();
          }}
          appearance="subtle">
          {!step ? 'Next' : 'Cancel'}
        </Button2>
        {step && (
          <Button3
            onClick={(event?: React.SyntheticEvent) => {
              event?.stopPropagation();
              setStep(step);
            }}
            appearance="subtle">
            Next
          </Button3>
        )}
      </Modal.Footer>
    </ModalPosition>
  );
};

export default ModalTools;
