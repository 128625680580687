/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { useTheme } from 'styled-components';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { useAuth } from '../../../context/AuthContext';
import axios from '../../../lib/axios';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { notificationError } from '../../../lib/notification';
import { assignAdAccount } from '../../../services/assignAdAccount';
import { assignTool } from '../../../services/assignTool';
import { disableTool } from '../../../services/disableTool';
import { getToolsCompanyObj } from '../../../services/getToolsCompany';
import {
  facebookConnect,
  facebookLogin,
  facebookPageSelected,
} from '../../../services/facebookTools';
import ModalTools from '../../modals/modalTools/ModalTools';
import {
  ButtonAdd,
  ButtonContainer,
  CardPage,
  Container,
  NoAccount,
} from '../Toogle.styles';

interface FacebookToogleRef {
  handleClick: () => void;
}
interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
  newFormat?: boolean;
  clickedSocial?: string;
  socialNetworkPages?: {
    facebook: boolean;
    google: boolean;
    instagram: boolean;
    twitter: boolean;
    tiktok: boolean;
  };
  updateParentPages?: () => Promise<void>;
}

const FacebookToogle = forwardRef<FacebookToogleRef, Props>(
  (
    {
      companyId,
      stateTool,
      dispatchTool,
      invalidateQuery,
      signup = false,
      newFormat = false,
      clickedSocial,
      socialNetworkPages,
      updateParentPages,
    }: Props,
    ref,
  ) => {
    const theme = useTheme();
    const [step, setStep] = useState<'page' | 'ads' | 'switch'>('page');
    const [facebookData, setFacebookData] = useState<FacebookData | null>(null);
    const [switchData, setSwitchData] = useState<[] | null>(null);
    const [dataModalArray, setDataModalArray] = useState<string[]>([]);

    useImperativeHandle(
      ref,
      () => ({
        handleClick: () => {
          if (clickedSocial === 'FACEBOOK') {
            handleLoginFacebook();
          }
        },
      }),
      [clickedSocial],
    );

    const { user } = useAuth();

    const activated =
      stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]?.enabled === true;

    const handleLoginFacebook = async (event?: React.SyntheticEvent) => {
      event?.stopPropagation();

      try {
        setFacebookData(null);
        const facebookAcoount = await facebookLogin();

        if (!facebookAcoount) {
          console.log('Facebook login was cancelled or window was closed');
          return;
        }
        const { data } = await facebookConnect(
          facebookAcoount as fb.StatusResponse,
        );

        if (data) {
          setFacebookData(data);
        }

        // If instagram is connected and user is logged in, check if we still have permissions for connected Instagram page
        if (stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]?.enabled && user) {
          const pages = data.pages?.map((page: { id: string }) => page.id);

          const { data: instagramCheckData } = await axios.post(
            '/tools/instagram/check',
            {
              pages,
            },
          );

          if (instagramCheckData.instagramToolWasDisabled) {
            notificationError({
              title: 'Instagram',
              description:
                'Instagram tool was disabled, because we lost permissions for connected page',
            });
            dispatchTool({
              type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM,
            });
          }
        }
      } catch (err) {
        notificationError({
          title: 'Error',
          description: `There was an error while connecting to Facebook`,
        });
      }
    };

    const handleAddSelectedPagesClick = async (
      page: FacebookPage,
      event?: React.SyntheticEvent,
    ) => {
      event?.stopPropagation();
      if (dataModalArray.includes(page.id)) {
        return;
      }
      if (updateParentPages) {
        await updateParentPages();
      }
      setDataModalArray(prev => [...prev, page.id]);

      const fbPageArray: FacebookPage[] = [page];

      if (facebookData) {
        await handleSelectPages(fbPageArray, facebookData?.facebookUserId);
      }
    };

    const handleSelectPages = async (
      pages: FacebookPage[],
      facebookUserId: string,
      event?: React.SyntheticEvent,
    ) => {
      event?.stopPropagation();
      const fbPages = await facebookPageSelected(pages);

      const transformedPages: Tool[] = fbPages.map(
        (selectedPage: FacebookPage) => ({
          type: TYPES_OF_SOCIAL_NETWORKS.FACEBOOK,
          pageId: selectedPage.id,
          userId: facebookUserId,
          accessToken: selectedPage.access_token,
          enabled: dataModalArray.length === 0,
          pageName: `${selectedPage.name} - ${selectedPage.category}`,
          adAccountId: null,
          profilePic: selectedPage.picture,
          pageDetails: selectedPage.pageDetails,
        }),
      );

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: transformedPages,
        });

        if (data && data[0] && dataModalArray.length === 0) {
          dispatchTool({
            type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
            payload: data[0],
          });
        }
      } else {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
          payload:
            transformedPages[0] /** bug check what to add instead of transformedPages  */,
        });
      }
      invalidateQuery?.();
      //  setStep('ads');
    };

    const handleSelectAdAccount = async (
      adAccountId: string,
      event?: React.SyntheticEvent,
    ) => {
      event?.stopPropagation();

      if (companyId) {
        await assignAdAccount(companyId, adAccountId);
      }
      dispatchTool({
        type: TYPES_TOOLS.CONNECT_AD_ACCOUNT,
        payload: {
          adAccountId,
        },
      });
      setFacebookData(null);
      setDataModalArray([]);
      setStep('page');
    };

    const handleShowPages = async (event?: React.SyntheticEvent) => {
      event?.stopPropagation();

      const { data: dataTools } = await getToolsCompanyObj(companyId);
      setSwitchData(dataTools.facebook);
      setStep('switch');
    };
    const handleSelectPage = async (
      page: any,
      event?: React.SyntheticEvent,
    ) => {
      event?.stopPropagation();
      const defaultSelectContent = [
        {
          type: TYPES_OF_SOCIAL_NETWORKS.FACEBOOK,
          accessToken: page.accessToken,
          pageId: page.pageId,
          // pageName: `${name} - ${category}`,
          userId: page.userId,
          // adAccountId: null,
          enabled: true,
          id: page.id,
        },
      ];

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: defaultSelectContent,
        });
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
          payload: data[0],
        });
        setSwitchData(null);
      }
    };

    // const wid = newFormat ? '69%' : '100%';
    // const gp = newFormat ? '8px' : '0px';

    return (
      <>
        {facebookData &&
          step === 'page' &&
          (!facebookData?.pages || facebookData?.pages?.length === 0) && (
            <ModalTools
              setDataModal={setFacebookData}
              dataModal={facebookData}
              title="Connect Facebook"
              description="No connected pages as admin">
              <CardPage align="middle">
                <FlexboxGrid.Item>
                  <span>
                    No connected pages. Talk to your Facebook admin to get
                    connected.
                  </span>
                </FlexboxGrid.Item>
              </CardPage>
            </ModalTools>
          )}
        {facebookData && step === 'page' && facebookData?.pages && (
          <ModalTools
            setDataModal={setFacebookData}
            dataModal={facebookData}
            title="Connect Facebook"
            description="Select the Facebook page"
            setDataModalArray={setDataModalArray}
            setStep={setStep}
            step="ads">
            {facebookData?.pages.length > 0 ? (
              <div>
                {facebookData.pages.map((page: FacebookPage) => {
                  const isDisabled = dataModalArray.includes(page.id);
                  return (
                    <CardPage align="middle" key={page.id}>
                      <FlexboxGrid.Item xs={20} componentClass={Col}>
                        <span>
                          {page.name} - {page.category}
                        </span>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item xs={4} componentClass={Col}>
                        <Button
                          disabled={isDisabled}
                          onClick={(event: React.SyntheticEvent) => {
                            event.stopPropagation();
                            handleAddSelectedPagesClick(page);
                          }}>
                          Add Page
                        </Button>
                      </FlexboxGrid.Item>
                    </CardPage>
                  );
                })}
              </div>
            ) : (
              <NoAccount>No Facebook Business Page found</NoAccount>
            )}
          </ModalTools>
        )}

        {facebookData && step === 'ads' && (
          <ModalTools
            setDataModal={setFacebookData}
            dataModal={facebookData}
            title="Connect Facebook"
            description="Select ad account">
            {facebookData?.accountData.length > 0 ? (
              <div>
                {facebookData.accountData.map(
                  (account: FundingSourceDetails) => {
                    return (
                      <CardPage align="middle" key={account.id}>
                        <FlexboxGrid.Item xs={20} componentClass={Col}>
                          <span>
                            {account.business_name} - {account.id.split('_')[1]}
                          </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item xs={4} componentClass={Col}>
                          <Button
                            onClick={(event: React.SyntheticEvent) => {
                              event.stopPropagation();
                              handleSelectAdAccount(account.id);
                            }}>
                            Add
                          </Button>
                        </FlexboxGrid.Item>
                      </CardPage>
                    );
                  },
                )}
              </div>
            ) : (
              <NoAccount>No Facebook Ads Account found</NoAccount>
            )}
          </ModalTools>
        )}

        {switchData && step === 'switch' && (
          <ModalTools
            setDataModal={setSwitchData}
            dataModal={switchData}
            title="Switch Facebook pages"
            description="Select the Facebook page to switch to"
            setStep={setStep}
            step="page">
            <div>
              {switchData?.map((page: any) => {
                return (
                  <CardPage align="middle" key={page.pageId}>
                    <FlexboxGrid.Item componentClass={Col} xs={20}>
                      <span>{page.pageName}</span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item xs={4} componentClass={Col}>
                      <Button
                        disabled={page.enabled}
                        onClick={(event: React.SyntheticEvent) => {
                          event.stopPropagation();
                          handleSelectPage(page, event);
                        }}>
                        {page.enabled ? 'Activated' : 'Activate'}
                      </Button>
                    </FlexboxGrid.Item>
                  </CardPage>
                );
              })}
            </div>
          </ModalTools>
        )}

        {/* <> */}
        {/* {activated && (
          <Container
            style={{
              display: 'flex',
              flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
              justifyContent: signup ? 'center' : 'space-between',
              alignItems: 'center',
              padding: signup ? '20px' : '10px',
              borderRadius: signup ? '10px' : '0',
              width: signup ? '20%' : '100%', // Set width to 40% when signup is true
              margin: '0 auto', // Center the container
              gap: signup ? '10px' : '0', // Add gap between buttons in column layout
            }}>
            <ButtonAdd
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}>
              Switch
            </ButtonAdd>
            <ButtonAdd
              // bgColor={theme.colors.red}
              bgColor={signup ? 'black' : theme.colors.crimson}
              onClick={() => {
                if (companyId) {
                  disableTool(
                    stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                  ).then(() => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                    invalidateQuery?.();
                  });
                } else {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                }
              }}
              style={{
                width: signup ? '120px' : 'auto',
              }}>
              Disconnect
            </ButtonAdd>
          </Container>
        )}

        {!activated && (
          <ButtonContainer signup={signup}>
            <ButtonAdd
              bgColor={theme.colors.red}
              onClick={handleLoginFacebook}
              signup={signup}>
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </> */}

        {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? handleLoginFacebook()
            : companyId
              ? disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id).then(
                () => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                  invalidateQuery?.();
                },
              )
              : dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK })
        }
      /> */}
        {signup ? (
          // When signup is true, show Connect and Disconnect
          <>
            {activated && (
              <Container
                style={{
                  // display: 'flex',
                  // flexDirection: signup ? 'column' : 'row',
                  justifyContent: signup ? 'end' : 'space-between',
                  // alignItems: 'center',
                  // padding: signup ? '20px' : '10px',
                  // borderRadius: signup ? '10px' : '0',
                  // width: signup ? '20%' : '100%',
                  // margin: '0 auto',
                  // gap: signup ? '10px' : '0',
                }}>
                {/* <ButtonAdd
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}>
              Switch
            </ButtonAdd> */}
                {!newFormat && (
                  <ButtonAdd
                    bgColor={signup ? '#fff' : theme.colors.grey} // Set background color based on signup
                    // bgColor={signup ? '#fff' : theme.colors.crimson}
                    onClick={(event: React.SyntheticEvent) => {
                      event.stopPropagation();
                      if (companyId) {
                        disableTool(
                          stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                        ).then(() => {
                          dispatchTool({
                            type: TYPES_TOOLS.DEACTIVATE_FACEBOOK,
                          });
                          invalidateQuery?.();
                        });
                      } else {
                        dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                      }
                    }}
                    disabled={signup}
                    pad={!activated ? '8px 12px' : '8px 4px'}
                    style={{
                      width: signup ? '120px' : 'auto',
                      color: signup ? '#64E000' : 'inherit',
                      fontFamily: signup ? "'Inter', sans-serif" : 'inherit',
                      cursor: signup ? 'not-allowed' : 'pointer',
                      fontSize: signup ? '14px' : 'inherit',
                      padding: signup
                        ? '2px 15px'
                        : !activated
                        ? '8px 12px'
                        : '8px 4px',
                    }}>
                    {/* Disconnect */}
                    Connected
                  </ButtonAdd>
                )}
              </Container>
            )}

            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={signup ? theme.colors.red : theme.colors.grey}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    handleLoginFacebook();
                  }}
                  pad={!activated ? '8px 12px' : '8px 4px'}
                  signup={signup}>
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        ) : (
          // When signup is false, show Switch and Disconnect
          <>
            {activated && (
              <Container
                style={{
                  display: 'flex',
                  flexDirection: signup ? 'column' : 'row',
                  justifyContent: signup ? 'center' : 'space-between',
                  alignItems: 'center',
                  padding: signup ? '20px' : 'inherit',
                  borderRadius: signup ? '10px' : 'inherit',
                  width: signup ? '20%' : '',
                  gap: signup ? '10px' : '5px',
                }}>
                {activated && socialNetworkPages?.facebook && (
                  <>
                    {/* {console.log("facebook vale",facebookData.pages?.length)} */}
                    <ButtonAdd
                      bgColor={signup ? 'black' : theme.colors.crimson}
                      color={signup ? 'black' : theme.colors.crimson}
                      pad={!activated ? '8px 12px' : ''}
                      width={signup ? '30px' : 'auto'}
                      onClick={(event: React.SyntheticEvent) => {
                        event?.stopPropagation();
                        handleShowPages();
                      }}
                      style={{
                        width: signup ? '120px' : 'auto',
                        marginBottom: signup ? '5px' : '0',
                      }}>
                      Switch
                    </ButtonAdd>
                  </>
                )}
                <ButtonAdd
                  // bgColor={theme.colors.red}
                  bgColor={signup ? 'black' : theme.colors.crimson}
                  pad={!activated ? '8px 12px' : ''}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    if (companyId) {
                      disableTool(
                        stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                      ).then(() => {
                        dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                        invalidateQuery?.();
                      });
                    } else {
                      dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                    }
                  }}
                  style={{
                    width: signup ? '120px' : '',
                  }}>
                  Disconnect
                </ButtonAdd>
              </Container>
            )}

            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={theme.colors.grey}
                  pad={!activated ? '8px 12px' : '8px 4px'}
                  onClick={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    handleLoginFacebook();
                  }}>
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        )}
      </>
    );
  },
);

export default FacebookToogle;
