import axios from '../lib/axios';

export const sendWelcomeEmail = async (userId: number, email: string) => {
  try {
    const response = await axios.post('/mail/welcome', {
      userId,
      email,
    });

    return response;
  } catch (error) {
    // notificationError({
    //   title: 'Error',
    //   description: 'Failed to send the welcome email.',
    // });

    return error;
  }
};
export const sendWelcomeEmail2 = async (email: string) => {
  try {
    // console.log('reached here for snding email');
    const response = await axios.post('/mail/welcome2', {
      email,
    });

    return response;
  } catch (error) {
    // notificationError({
    //   title: 'Error',
    //   description: 'Failed to send the welcome email.',
    // });

    return error;
  }
};
