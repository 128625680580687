/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import { useQueryClient } from 'react-query';
import { Col, Divider, List, Modal, Row, Loader } from 'rsuite';
import EmojiPicker from 'emoji-picker-react';
import axios from '../../../lib/axios';
import { notification, notificationWithNodes } from '../../../lib/notification';
import {
  ButtonGrey,
  ButtonRed,
  ModalPosition,
  TextArea,
  TitleSection,
  DateP,
  Container,
  ButtonNotification,
  MessageTextAreaWrapper,
  MessageIconSmiley,
  StyledList,
  ReplySuggestionWrapper,
  ReplySuggestionButton,
  ResponsiveModal,
  ResponsiveRow,
} from './ReplyMessageModal.styles';
import { formatMessageDateTime } from '../../../lib/format';
import smileIcon from '../../../assets/images/smile.png';
import { useAuth } from '../../../context/AuthContext';

interface Props {
  toggleShowModal: () => void;
  showModal: boolean;
  messages: TMessage;
  handleUpdate: () => void;
}

interface EmojiObject {
  emoji: string;
}

const ReplyMessageModal = ({
  messages,
  showModal,
  toggleShowModal,
  handleUpdate,
}: Props) => {
  const { user } = useAuth();
  const [replyText, setReplyText] = useState('');
  const [messageSuggestions, setmessageSuggestions] =
    useState<string[] | null>(null);
  const [suggestionGenerator, setSuggestionGenerator] = useState(false);
  const history = useHistory();
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const trialEndDate = user?.companies?.[0]?.payment?.trialEndDate ?? null;

  useEffect(() => {
    const { subscriptionStatus, invoiceStatus, hasAccess } =
      user?.companies?.[0]?.payment ?? {};
    const isInTrialPeriod = trialEndDate && new Date(trialEndDate) > new Date();
    if (
      (subscriptionStatus === 'active' && invoiceStatus === 'paid') ||
      (subscriptionStatus === 'active_lifetime' && invoiceStatus === 'paid') ||
      isInTrialPeriod ||
      (subscriptionStatus === 'trialing' &&
        invoiceStatus === 'paid' &&
        hasAccess) ||
      (subscriptionStatus === 'trial_expired' && !hasAccess)
    ) {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  // const queryClient = useQueryClient();
  const handleMessageSuggestions = async () => {
    try {
      console.log('plan is handleMessageSuggestions ', displayPremiumPlan);
      if (displayPremiumPlan) {
        setIsLoading(true);
        const suggestions = await axios.post(`chatbot/message-suggestions`, {
          messages: messages.messages.data,
        });
        setIsLoading(false);
        setSuggestionGenerator(true);
        setmessageSuggestions(suggestions.data);
      } else {
        notificationWithNodes({
          type: 'info',
          title: 'Upgrade your plan',
          description: (
            <>
              <p>
                You&apos;ll need to upgrade to a premium plan to activate this
                feature.
              </p>
              <ButtonNotification
                onClick={() => history.push('/user-panel/account')}>
                See plans
              </ButtonNotification>
            </>
          ),
          duration: 10000,
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      if (
        error.response.statusText === 'Forbidden' &&
        error.response.status === 403
      ) {
        const { message } = error.response.data;
        if (
          message ===
          `You'll need to upgrade to a premium plan to activate this feature`
        ) {
          notificationWithNodes({
            type: 'info',
            title: 'Upgrade your plan',
            description: (
              <>
                <p>
                  You&apos;ll need to upgrade to a premium plan to activate this
                  feature.
                </p>
                <ButtonNotification
                  onClick={() => history.push('/user-panel/account')}>
                  See plans
                </ButtonNotification>
              </>
            ),
            duration: 10000,
          });
        }
      }
    }
  };
  const handleReply = async () => {
    console.log('handle reply messege ', displayPremiumPlan);

    try {
      if (displayPremiumPlan) {
        await axios.post(`tools/messages/${messages.senderId}/reply`, {
          message: replyText,
          type: messages.type,
        });
        notification({
          type: 'success',
          title: 'Success',
          description: 'Reply was sent',
        });

        // update component on every message sent from dinabite
        handleUpdate();

        toggleShowModal();
        setReplyText('');
        // queryClient.setQueryData<Message[] | undefined>(
        //   'MESSAGES',
        //   (old: Message[] | undefined) => {
        //     if (old) {
        //       const updatedMessages = old.map(oldMessage => {
        //         // if (
        //         //   oldMessage.type !== 'twitter'
        //         //   // oldMessage.id === message.id
        //         // ) {
        //           console.log('line 55 | ReplyMessageModal.ts | oldMessage: ',oldMessage)
        //           return { ...oldMessage };
        //         // }
        //         // return oldMessage;
        //       });
        //       return updatedMessages;
        //     }
        //     return old;
        //   },
        // );
      } else {
        notificationWithNodes({
          type: 'info',
          title: 'Upgrade your plan',
          description: (
            <>
              <p>
                You&apos;ll need to upgrade to a premium plan to activate this
                feature.
              </p>
              <ButtonNotification
                onClick={() => history.push('/user-panel/account')}>
                See plans
              </ButtonNotification>
            </>
          ),
          duration: 10000,
        });
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const { message } = err.response.data;
        if (
          message ===
          `You'll need to upgrade to a premium plan to activate this feature`
        )
          notificationWithNodes({
            type: 'info',
            title: 'Upgrade your plan',
            description: (
              <>
                <p>
                  You&apos;ll need to upgrade to a premium plan to activate this
                  feature.
                </p>
                <ButtonNotification
                  onClick={() => history.push('/user-panel/account')}>
                  See plans
                </ButtonNotification>
              </>
            ),
            duration: 10000, // 10 sec
          });
      }
    }
  };

  const handleEmojiClick = (emojiObject: EmojiObject) => {
    setReplyText(replyText + emojiObject.emoji);
  };

  const toggleEmojiPicker = () => {
    if (!displayPremiumPlan) {
      notificationWithNodes({
        type: 'info',
        title: 'Upgrade your plan',
        description: (
          <>
            <p>
              You&apos;ll need to upgrade to a premium plan to activate this
              feature.
            </p>
            <ButtonNotification
              onClick={() => history.push('/user-panel/account')}>
              See plans
            </ButtonNotification>
          </>
        ),
        duration: 10000,
      });
    } else {
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    }
  };

  const closeEmojiPicker = () => {
    setIsEmojiPickerOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      closeEmojiPicker();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSuggestionClick = (suggestion: string) => {
    const suggestionText = suggestion.replace(/Suggestion \d+: /, '');

    setReplyText(suggestionText);

    setmessageSuggestions(prevSuggestions =>
      prevSuggestions ? prevSuggestions.filter(s => s !== suggestion) : null,
    );
  };

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
    if (!showModal) {
      setTimeout(() => {
        setReplyText('');
        setmessageSuggestions(null);
        setSuggestionGenerator(false);
      }, 1000);
    }
  }, [showModal]);

  return (
    <ModalPosition
      style={{ overflow: 'hidden' }}
      size="md"
      show={showModal}
      onHide={toggleShowModal}>
      {/* <ResponsiveModal>       */}
      <Modal.Header>
        <TitleSection>{messages.senderName}</TitleSection>
      </Modal.Header>
      <Divider />
      <Modal.Body style={{ padding: '10px', overflowX: 'hidden' }}>
        <ResponsiveRow style={{ padding: '10px', overflowX: 'hidden' }}>
          <Col xs={24} sm={24} md={24}>
            {messages.messages.data.map(value => (
              <>
                <Container
                  align={
                    (value?.from?.name || value?.from?.username) ===
                    messages.senderName
                      ? 'right'
                      : 'left'
                  }>
                  <DateP>{formatMessageDateTime(value.created_time)}</DateP>
                  <p>
                    {value?.message ||
                      value?.message_create?.message_data?.text}
                  </p>
                </Container>
              </>
            ))}
            <Divider />
            <TitleSection ref={bottomRef}>REPLY:</TitleSection>
            <MessageTextAreaWrapper ref={emojiPickerRef}>
              <TextArea
                onClick={closeEmojiPicker}
                value={replyText}
                onChange={e => setReplyText(e.target.value)}
                rows={8}
              />
              <MessageIconSmiley
                src={smileIcon}
                alt="smiley icon"
                onClick={toggleEmojiPicker}
              />
              {isEmojiPickerOpen && (
                <EmojiPicker onEmojiClick={handleEmojiClick} />
              )}
            </MessageTextAreaWrapper>
            <Divider />
            <ReplySuggestionWrapper ref={bottomRef}>
              <TitleSection>
                Reply suggestions.
                {suggestionGenerator ? ' Click on the best one.' : ''}
              </TitleSection>

              {messageSuggestions?.map(suggestion => (
                // <SuggestionsContainer onClick={() => handleSuggestionClick(suggestion)}>
                <StyledList
                  hover
                  onClick={() => handleSuggestionClick(suggestion)}>
                  <List.Item>{suggestion}</List.Item>
                </StyledList>
                // </SuggestionsContainer>
              ))}
              <ReplySuggestionButton
                bgColor={suggestionGenerator}
                disabled={isLoading || suggestionGenerator}
                onClick={handleMessageSuggestions}
                appearance="subtle"
                size="md">
                {isLoading ? (
                  <Loader content="Loading..." />
                ) : (
                  <span>Generate reply suggestions</span>
                )}
              </ReplySuggestionButton>
            </ReplySuggestionWrapper>
            <Divider />
          </Col>
        </ResponsiveRow>
      </Modal.Body>
      <Modal.Footer style={{ padding: '18px' }}>
        <Row gutter={5}>
          <Col md={12} />
          <Col xs={24} md={6}>
            <ButtonGrey onClick={toggleShowModal} appearance="subtle" size="md">
              Cancel
            </ButtonGrey>
          </Col>
          <Col xs={24} md={6}>
            <ButtonRed
              onClick={handleReply}
              disabled={!replyText}
              appearance="subtle"
              size="md">
              Send
            </ButtonRed>
          </Col>
        </Row>
      </Modal.Footer>
      {/* </ResponsiveModal> */}
    </ModalPosition>
  );
};

export default ReplyMessageModal;
