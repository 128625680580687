import { NavLink } from 'react-router-dom';
import { Button, Nav, Sidenav } from 'rsuite';
import styled, { css } from 'styled-components';

interface IndicatorProps {
  isActive: boolean;
}

export const SidenavStyled = styled(Sidenav)<{ toolsLength: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  // gap: 150px;
  gap: ${props => (props.toolsLength > 0 ? '100px' : '300px')};

  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.white};
  // @media ${props => props.theme.breakpoints.md},
  //   ${props => props.theme.breakpoints.lg} {
  //   padding-left: 20px;
  // }
  // @media ${props => props.theme.breakpoints.sm},
  //   ${props => props.theme.breakpoints.md} {
  //   padding-left: 20px;
  // }
`;
export const Navigation = styled(Nav)<{
  isMenuClose: boolean;
  toolsLength: number;
}>`
  position: relative;
  width: inherit;
  padding: 10px;
  // height: ${props => (props.isMenuClose ? '58%' : '')};
  height: ${props => (props.toolsLength > 0 ? '58%' : '35%')};

  ul {
    display: ${props => (props.isMenuClose ? 'flex' : '')};
    flex-direction: ${props => (props.isMenuClose ? 'column' : '')};
    justify-content: ${props => (props.isMenuClose ? 'space-around' : '')};
    align-items: ${props => (props.isMenuClose ? 'center' : '')};
    height: ${props => (props.isMenuClose ? '100%' : '')};
  }

  li {
    height: ${props => (props.isMenuClose ? '11%' : '')};
    padding: ${props => (props.isMenuClose ? '0px' : '0px 0px 0px 10px')};

    a {
      padding: ${props =>
        props.isMenuClose ? '0px !important' : '0px 0px 0px 10px'};
    }
  }
`;

export const Menu = styled(Nav.Item)`
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color:black;
  @supports (
    // (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    // overflow: visible !important;
  }
`;

export const StyledNavLink = styled(NavLink)<{ isMenuClose: boolean }>`
  .rs-nav-item-content:active {
    background: none;
  }
  .rs-icon {
    top: 10px !important;
    font-size: ${props =>
      props.isMenuClose ? '35px !important' : '25px !important'};
    left: ${props =>
      props.isMenuClose ? '42px !important' : '20px !important'};
    position: ${props =>
      props.isMenuClose ? 'inherit !important' : 'absolute !important'};
    margin-right: ${props =>
      props.isMenuClose ? '0px !important' : '20px !important'};
  }

  &.${props => props.activeClassName} {
    color: ${props => props.theme.colors.red};
    font-weight: bold;
    svg g line,
    path,
    rect,
    circle {
      // stroke: ${props => props.theme.colors.red};
    }

    // @media ${props => props.theme.breakpoints.md},
    //   ${props => props.theme.breakpoints.lg} {
    //   &:after {
    //     content: '';
    //     position: absolute;
    //     width: 32%;
    //     height: 100%;
    //     background-color: ${props => props.theme.colors.red};
    //     border-radius: 100px;
    //     right: -13%;
    //     bottom: 0;
    //   }
    // }
  }
`;

export const DividerSpace = styled.div`
  height: 1px;
  background-color: ${props => props.theme.colors.grey};

  @media ${props => props.theme.breakpoints.md},
    ${props => props.theme.breakpoints.lg} {
    margin: 10px 10% 10px;
  }
`;
export const MenuItem = styled(Nav.Item)`
  position: relative; /* Position for the indicator */
  padding-left: 20px; /* Space for the indicator */
`;

export const UpGradeButton = styled(Button)<{ isMenuClose: boolean }>`
  font-family: 'Inter', sans-serif;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  background-color: ${props => props.theme.colors.red};
  padding: 12px 0;
  border-radius: 20px;
  color: ${props => props.theme.colors.white};
  transform: ${props =>
    props.isMenuClose ? 'rotate(-90deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease; /* Add transition for smooth rotation */
    transition: background 3s ease, color 1s ease;

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
 
`;

export const Indicator = styled.div<IndicatorProps>`
  width: 8px; /* Adjusted width of the indicator */
  height: 60px; /* Fixed height to match your UI */
  background-color: #dd2256; /* Color of the indicator */
  position: absolute; /* Positioned absolutely inside the parent */
  left: 0; /* Align to the left side of the parent */
  top: 50%; /* Vertically center */
  transform: translateY(-50%); /* Adjust for centering */

  /* If active, show the indicator; if not, hide it */
  ${({ isActive }) =>
    isActive
      ? css`
          visibility: visible; /* Show the indicator if active */
          opacity: 1; /* Fully visible */
        `
      : css`
          visibility: hidden; /* Hide if not active */
          opacity: 0; /* Fully invisible */
        `}

  transition: visibility 0.2s ease, opacity 0.2s ease; /* Smooth transition */
`;
