import { Button, Modal } from 'rsuite';
import styled from 'styled-components';

export const ModalTitle = styled.p`
  font-weight: bolder;
  font-size: ${props => props.theme.fonts.title};
`;

export const ModalSubtitle = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
`;

export const Button2 = styled(Button)`
  color: ${props => props.theme.colors.white};
  background-color:${props => props.theme.colors.red} ;
  padding: 8px 40px;
  border-radius: 40px;
  color: white;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  margin: 10px 10px; 
  min-width: 80px;
  &:disabled {
    background-color: #${props => props.theme.colors.white};
      color: ${props => props.theme.colors.red};
  }

`;
export const Button3 = styled(Button)`
  color: ${props => props.theme.colors.white};
  background-color:${props => props.theme.colors.red} ;
  padding: 8px 48px;
  border-radius: 40px;
  color: white;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  margin: 0px 10px; 
  min-width: 80px;

  &:disabled {
    background-color: #${props => props.theme.colors.white};
      color: ${props => props.theme.colors.red};

  }

`;