/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

interface User {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
}

interface ValuesForm extends User {
  name: string;
  country: string;
  accountType?: string;
}

interface ValuesFormEmail extends User {
  password: string;
}

interface CreateCompanyWithUserId {
  userId: number;
  name: string;
  country: string;
  accountType?: string;
}
interface ActivationResponse {
  message: string;
}

interface CreateCompanyResponse {
  success: boolean;
  message: string;
  companyId?: string;
}

interface CreateCompanyRequest {
  email: string;
  name: string;
  country: string;
  address: string;
  city: string;
  zipCode: string;
  phoneCode: string;
  phone: string;
}
export const createUser = async (user: ValuesFormEmail) => {
  try {
    const { firstName, lastName, email, password } = user;
    // console.log("create user data is ");
    // console.log("firstName",firstName);
    // console.log("lastName",lastName);
    // console.log("email",email)
    // console.log("password",password)
    const response = await axios.post('users', {
      firstName,
      lastName,
      email,
      password,
    });

    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notificationError({
      title: 'Error',
      description: error.response.data.message,
    });
    return error;
  }
};

export const createUserEmail = async (
  values: CreateCompanyWithUserId,
  onSuccess: any,
) => {
  try {
    const { name, country, userId } = values;
    const response = await axios.post('companies/company-user', {
      name,
      country,
      userId,
    });
    onSuccess();
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notificationError({
      title: 'Error',
      description: error.response?.data?.message || 'An error occurred',
    });
    return error;
  }
};

export const createUserSocial = async (values: ValuesForm, onSuccess: any) => {
  try {
    const { firstName, lastName, email, name, country, accountType, password } =
      values;
    const response = await axios.post('companies/company-user', {
      firstName,
      lastName,
      name,
      email,
      country,
      accountType,
      password,
    });
    onSuccess();
    return response;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notificationError({
      title: 'Error',
      description: error.response.data.message,
    });
    return error;
  }
};
export const checkAccountActivation = async (
  email: string,
): Promise<ActivationResponse> => {
  try {
    const response = await axios.post('users/check-activation', { email });
    return response.data; // Ensure this matches the ActivationResponse structure
  } catch (error) {
    // notificationError({
    //   title: 'Error',
    //   description: error.response?.data?.message || 'Account is not activated',
    // });
    // throw error; // Rethrow the error for further handling
    return {
      message: 'Account is not activated',
    };
  }
};

export const createCompany = async (
  companyData: CreateCompanyRequest,
  onSuccess?: () => void,
): Promise<CreateCompanyResponse> => {
  try {
    const response = await axios.post('/companies/create-company', companyData);

    // Call the success callback if provided
    if (onSuccess) {
      onSuccess();
    }

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    notificationError({
      title: 'Error',
      description: error.response?.data?.message || 'Failed to create company',
    });

    return {
      success: false,
      message: error.response?.data?.message || 'Failed to create company',
    };
  }
};
